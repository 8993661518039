import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
declare let $: any;
import {CONSTANTES} from './shared/model/constantes'
import { SubscriptionService } from './shared/service/subscription.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;


    constantes = CONSTANTES;
    hasLogoConfigured = false;
    paramsGeneralList: any[];
      //start initialColor array;
    //   #ff7f2b
    // paramsGeneralList = [
    // {code: 'default-color', valeur: '#ff7f2b',typeValeur: 'Couleur'},
    // {code: 'primary-color', valeur: '#999999',typeValeur: 'Couleur'},
    // {code: 'secondary-color', valeur: '#000000',typeValeur: 'Couleur'},
    // {code: 'tertiary-color', valeur: '#CCCCCC',typeValeur: 'Couleur'}];
    //end initialColor array;

    constructor(private router: Router,private subscriptionService: SubscriptionService) {
        this.parametrageGeneral();
        // this.getParamsGen();
    }

    ngOnInit(){
        this.recallJsFuntions();
    }

    recallJsFuntions() {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationStart ) {
                $('.preloader').fadeIn('slow');
            }
        });
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/main.js');
            $('.preloader').fadeOut('slow');
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }

    parametrageGeneral(){
        this.subscriptionService.listParams().subscribe((resp) => {
            if (resp['responseCode'] == 200) {
                let data = resp['data'];
                this.paramsGeneralList = data?.filter(el => el.typeValeur == this.constantes.TYPE_VALEUR.COULEUR || el.typeValeur == this.constantes.TYPE_VALEUR.IMAGE);
                this.hasLogoConfigured = !!data.find(el => el.code == this.constantes?.CODE_VALEUR?.LOGO_IMAGE);
                if (this.paramsGeneralList.length != 0) {
                  for (let item of this.paramsGeneralList) {
                    if (item.code == this.constantes?.CODE_VALEUR?.DEFAULT_COLOR) {
                     document.documentElement.style.setProperty(this.constantes?.CODE_CLASSE?.COLOR_DEFAULT, item.valeur);
                    } 
                    else if (item.code == this.constantes?.CODE_VALEUR?.PRIMARY_COLOR) {
                        document.documentElement.style.setProperty(this.constantes?.CODE_CLASSE?.COLOR_PRIMARY, item.valeur);
                    }
                     else if (item.code == this.constantes?.CODE_VALEUR?.SECONDARY_COLOR) {
                        document.documentElement.style.setProperty(this.constantes?.CODE_CLASSE?.COLOR_SECONDARY, item.valeur);
                    } 
                    else if (item.code == this.constantes?.CODE_VALEUR?.TERTIARY_COLOR) {
                        document.documentElement.style.setProperty(this.constantes?.CODE_CLASSE?.COLOR_TERTIARY, item.valeur);
                    }
                     else if (item.code == this.constantes?.CODE_VALEUR?.LOGO_IMAGE) {
                        document.documentElement.style.setProperty('--LogoImage', `url('${item.valeur}')`);
                        localStorage.setItem('LogoImage', item.valeur);
                    }
                  }
                    if (!this.hasLogoConfigured) {
                        document.documentElement.style.setProperty('--LogoImage', `url('assets/images/logo/logos.png')`);
                    }
                } 
                else {
                  document.documentElement.style.setProperty(this.constantes?.CODE_CLASSE?.COLOR_DEFAULT, this.constantes?.DEFAUL_CODE_COULEUR?.DEFAULT);
                  document.documentElement.style.setProperty(this.constantes?.CODE_CLASSE?.COLOR_PRIMARY, this.constantes?.DEFAUL_CODE_COULEUR?.PRIMARY);
                  document.documentElement.style.setProperty(this.constantes?.CODE_CLASSE?.COLOR_SECONDARY, this.constantes?.DEFAUL_CODE_COULEUR?.SECONDARY);
                  document.documentElement.style.setProperty(this.constantes?.CODE_CLASSE?.COLOR_TERTIARY, this.constantes?.DEFAUL_CODE_COULEUR?.TERTIARY);
                    if (!this.hasLogoConfigured) {
                        document.documentElement.style.setProperty('--LogoImage', `url('assets/images/logo/logos.png')`);
                    }
                }

            } else {

            }
        }, error => {

        });
  }


   getParamsGen(){
    if(this.paramsGeneralList.length != 0){
    for (let item of this.paramsGeneralList) {
      if (item.code == 'default-color') {
       document.documentElement.style.setProperty('--ColorDefault', item.valeur);
      } else if (item.code == 'primary-color') {
          document.documentElement.style.setProperty('--ColorPrimary', item.valeur);
      } else if (item.code == 'secondary-color') {
          document.documentElement.style.setProperty('--ColorSecondary', item.valeur);
      } else if (item.code == 'tertiary-color') {
          document.documentElement.style.setProperty('--ColorTertiary', item.valeur);
      } else if (item.code == 'logo-image') {
          document.documentElement.style.setProperty('--LogoImage', `url('${item.valeur}')`);
      } 
  }
  } 
  else {
    document.documentElement.style.setProperty('--ColorDefault', '#0090e5');
    document.documentElement.style.setProperty('--ColorPrimary', '#004d72 ');
    document.documentElement.style.setProperty('--ColorSecondary', '#0f172a ');
    document.documentElement.style.setProperty('--ColorTertiary', '#CCCCCC ');
  }
  }


}