import { Component, HostListener, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NavService, Menu } from '../../../shared/service/nav.service';
import { SubscriptionService } from 'src/app/shared/service/subscription.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilsService } from 'src/app/shared/service/utils.service';
import { fromEvent, Observable, Subscription } from "rxjs";
import { ViewChild } from '@angular/core';
import { AccountService } from 'src/app/shared/service/account.service';

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {

  scrollClass: string = "";
  isCollapsed:boolean=false;
  media: boolean = false;
  hidden: boolean = false;
  hidden1: boolean = false;
  hidden2: boolean = false;
  public menuItems: Menu[];
  public openSide : boolean = false;
  public activeItem: string = 'home';
  public active: boolean = false;
  public activeChildItem : string = ''
  public overlay: boolean = false;
  products: any = [];
  listener: any = [];
  items:any = [];
  pointer: boolean =false;
  declarersinistreForm = new FormGroup({
    lieu: new FormControl(null, [Validators.required]),
    numeroPolice: new FormControl(null ,[Validators.required, Validators.min(1), Validators.max(25)]),
    date: new FormControl(null ,[Validators.required]),
    description: new FormControl(null ,[Validators.required]),
    picture: new FormControl(null ,[Validators.required])
  });
  
  @ViewChild('contentResult', {static: false}) contentResult: any;
  utilisateur: any;
  subscription: Subscription;
  utilisateurs: any;
  logoImage = localStorage.getItem('LogoImage');



constructor(public router: Router, public navServices: NavService, private subscriptionService: SubscriptionService,private modalService: NgbModal,private utilsService: UtilsService,private accountService:AccountService) { 

  this.subscription=this.accountService.getAccount().subscribe(account=>{
    if(account)
    this.utilisateur = JSON.parse(localStorage.getItem('microFinance'));
  });
  if (window.screen.width < 991) { // 768px portrait
    this.media = false;
    this.hidden = false;
    this.hidden1 = false;
    this.hidden2 = false;
  }
  else if(window.screen.width > 991){
    this.hidden = false;
    this.hidden = false;
    this.hidden1 = false;
    this.hidden2 = false;
    this.media = true;  
  }
 
  this.navServices.items.subscribe(menuItems => {
    this.getProducts();
    this.menuItems = menuItems;
  });

}

  ngOnInit(): void {
    if(localStorage.getItem('microFinance'))
    this.utilisateur = JSON.parse(localStorage.getItem('microFinance'));
  this.listener = fromEvent(window, 'resize')
  this.listener = this.listener.subscribe( evt => {
        if (evt.target.innerWidth > 991) {
      this.media = true;       
    }
    else if (evt.target.innerWidth < 991){
      this.media = false;
      this.pointer = true;
    }
  });
  }
  deconnexion(){
    localStorage.removeItem('microFinance');
    this.router.navigate(['accueil']);
    this.accountService.sendAccount('');
    this.utilsService.showToastSuccess("Effectué avec succès", "Déconnexion");
   }

   onFileSelected(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // @ts-ignore
      this.declarersinistreForm.get('profile').setValue(file);
    }
  }
  getProducts(){
    this.subscriptionService.getProducts().subscribe((resp) => {
      this.products = resp['data'].filter(el=>(el.code === "ASSURANCE AUTOMOBILE" || el.code === "ASSURANCE MRH" || el.code === "ASSURANCE SANTE" || el.code === "ASSURANCE VOYAGE" || el.code === "ASSURANCE RCE" || el.code === "ASSURANCE TRC" || el.code === "ASSURANCE MRP"));
      let auto = this.products.splice(2,1);
      this.products.unshift(auto[0]);
      this.items = this.menuItems.map((item) =>{
          item = this.products.filter((p) =>{
             p['path'] = '/devis/'+p.id;
             p['title'] = (p.code).toLowerCase();
             p['type'] = 'link';
             return p;  
          }) 
      }); 
    });
  }
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    if(event.target.scrollingElement.scrollTop >= 70){
      this.scrollClass = "is-sticky";
     }
    else{
      this.scrollClass = "";
    }
  }

  ouvrir(){
    if(window.screen.width < 991){
          this.hidden = !this.hidden
        } 
        
    }
  
    ouvrir1(){
      if(window.screen.width < 991){
      this.hidden1 = !this.hidden1;
        }
      }
    ouvrir2(){
      if(window.screen.width < 991){
      this.hidden2 = !this.hidden2;
      }
    }
    // dropdown(){
    //   this.isCollapsed = true;
    // }
    openModalResult() {
      this.modalService.open(this.contentResult, {ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
        if(result == 'subscribe'){
          let data = {
            ...this.declarersinistreForm.value,
          };
          let police = {
            "numeroPolice" : this.declarersinistreForm.value.numeroPolice
          };
           
          this.subscriptionService.checkSinistre(police).subscribe((resp)=>{
            if(resp['responseCode'] == 200){
              if (resp['data']['actif'] == true) {
                this.subscriptionService.registerSinistre(data).subscribe((resp)=>{
                  if(resp['responseCode'] == 200){
                    this.utilsService.showToastSuccess(resp['message'], "Sinistre déclaré avec success");
                  }
                  else{
                        this.utilsService.showToastError(resp['message'], "Erreur lors de la déclaration");
                      }
                });
              }
            }
            else{
              this.utilsService.showToastError(resp['message'], "Ce numéro de police n'existe pas");
            }
          })    
        }   
      }, (reason) => {
      });
    }
    onDateSelect(event,  controlName){
      this.declarersinistreForm.get(''+controlName).setValue(new Date(event.year+'/'+event.month+'/'+event.day));
    }
    ngOnDestroy() {
      this.listener.unsubscribe()
      this.pointer=false;
    }
}
